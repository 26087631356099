import TemplateContents from './TemplateContents';

export default class extends TemplateContents {
    constructor(param) {
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset() {
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct() {
        super.destruct();

        this.confirmBtn.removeEventListener(
            'click',
            this.bindClickConfirmHandler
        );
        this.submitBtn.removeEventListener(
            'click',
            this.bindClickSubmitHandler
        );
        this.backBtn.removeEventListener('click', this.bindClickBackHandler);

        for (let i = 0, len = this.inputRowfields.length; i < len; i++) {
            let item = this.inputRowfields[i];
            item.removeEventListener('focus', this.bindFocusHandler[i]);
            item.removeEventListener('blur', this.bindBlurHandler[i]);
        }

        for (let i = 0, len = this.copyToClipboards.length; i < len; i++) {
            let item = this.copyToClipboards[i];
            item.removeEventListener(
                'click',
                this.bincClickCopyToClipboardHandlerList[i]
            );
        }

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars() {
        super.setVars();
    }

    setDom() {
        super.setDom();

        this.contactsContainer = document.querySelector(
            'main.contact .section2 .contacts'
        );

        this.h1 = document.querySelector('main.contact h1');
        this.section1 = document.querySelector('main.contact .section1');
        this.section2 = document.querySelector('main.contact .section2');
        this.inputList = document.querySelectorAll(
            'main.contact .section1 .contents_input ul li.item'
        );
        this.inputRowTits = document.querySelectorAll(
            'main.contact .section1 .contents_input .input_item .tit'
        );
        this.inputRowfields = document.querySelectorAll(
            'main.contact .section1 .contents_input .input_item input, main.contact .section1 .contents_input .input_item textarea'
        );
        this.confirmList = document.querySelectorAll(
            'main.contact .section1 .contents_confirm ul li.item'
        );
        this.confirmListUserData = document.querySelectorAll(
            'main.contact .section1 .contents_confirm ul li.item .user_data'
        );
        this.confirmBtn = document.querySelector(
            'main.contact .section1 .contents_input .bt'
        );
        this.submitBtn = document.querySelector(
            'main.contact .section1 .contents_confirm .submit_bt'
        );
        this.backBtn = document.querySelector(
            'main.contact .section1 .contents_confirm .back_bt'
        );
    }

    initEvents() {
        super.initEvents();

        this.bindClickConfirmHandler = this.clickConfirmHandler.bind(this);
        this.bindClickSubmitHandler = this.clickSubmitHandler.bind(this);
        this.bindClickBackHandler = this.clickBackHandler.bind(this);
        this.bindFocusHandler = [];
        this.bindBlurHandler = [];

        this.confirmBtn.addEventListener('click', this.bindClickConfirmHandler);
        this.submitBtn.addEventListener('click', this.bindClickSubmitHandler);
        this.backBtn.addEventListener('click', this.bindClickBackHandler);

        for (let i = 0, len = this.inputRowfields.length; i < len; i++) {
            let item = this.inputRowfields[i];
            this.bindFocusHandler[i] = this.focusHandler;
            this.bindBlurHandler[i] = this.blurHandler;
            item.addEventListener('focus', {
                handleEvent: this.bindFocusHandler[i],
                id: i,
                sc: this,
            });
            item.addEventListener('blur', {
                handleEvent: this.bindBlurHandler[i],
                id: i,
                sc: this,
            });
        }
    }

    setEvents() {
        this.bincClickCopyToClipboardHandlerList = [];

        for (let i = 0, len = this.copyToClipboards.length; i < len; i++) {
            let item = this.copyToClipboards[i];
            this.bincClickCopyToClipboardHandlerList[i] =
                this.clickCopyToClipboardHandlerList;
            item.addEventListener('click', {
                handleEvent: this.bincClickCopyToClipboardHandlerList[i],
                sc: this,
                id: i,
            });
        }
    }

    clickCopyToClipboardHandlerList(event) {
        let sc = this.sc;
        let id = this.id;
        navigator.clipboard.writeText(sc.addresses[id].innerText);
    }

    focusHandler(event) {
        let sc = this.sc;
        let id = this.id;

        sc.inputRowTits[id].classList.add('small');
    }

    blurHandler(event) {
        let sc = this.sc;
        let id = this.id;
        // trace(sc.inputRowfields[id].value);
        if (sc.inputRowfields[id].value === '')
            sc.inputRowTits[id].classList.remove('small');
    }

    checkName(v) {
        if (v == '') return false;
        else return true;
    }

    checkEmail(v) {
        if (v == '') return false;
        else if (
            !v.match(
                /^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/
            )
        )
            return false;
        else return true;
    }

    checkMessage(v) {
        if (v == '') return false;
        else return true;
    }

    clickConfirmHandler(event) {
        let fname = document.querySelector('input[name="fname"]').value;
        let lname = document.querySelector('input[name="lname"]').value;
        let company = document.querySelector('input[name="company"]').value;
        let role = document.querySelector('input[name="role"]').value;
        let email = document.querySelector('input[name="email"]').value;
        let requirements = document.querySelector(
            'input[name="requirements"]'
        ).value;
        let message = document.querySelector('textarea[name="message"]').value;
        // let news = document.querySelector('input[name="news"]').checked;
        let privacy = document.querySelector(
            'input[name="privacy_policy"]'
        ).checked;

        let fnameResult = this.checkName(fname);
        let lnameResult = this.checkName(lname);
        // let companyResult = company;
        // let roleResult = role;
        let emailResult = this.checkEmail(email);
        // let requirementsResult = requirements;
        let messageResult = this.checkMessage(message);
        // let newsResult = news;
        let privacyResult = privacy;

        let liName1 = this.inputList[0];
        let liName2 = this.inputList[1];
        let liEmail = this.inputList[4];
        let liMessage = this.inputList[6];
        let liPrivacy = this.inputList[7];

        let liFName = this.pack.LANG === 'ja' ? liName2 : liName1;
        let liLName = this.pack.LANG === 'ja' ? liName1 : liName2;

        if (fnameResult) liFName.classList.remove('error');
        else liFName.classList.add('error');

        if (lnameResult) liLName.classList.remove('error');
        else liLName.classList.add('error');

        if (emailResult) liEmail.classList.remove('error');
        else liEmail.classList.add('error');

        if (messageResult) liMessage.classList.remove('error');
        else liMessage.classList.add('error');

        if (privacyResult) liPrivacy.classList.remove('error');
        else liPrivacy.classList.add('error');

        if (
            fnameResult &&
            lnameResult &&
            emailResult &&
            messageResult &&
            privacyResult
        ) {
            this.confirmListUserData[0].innerText =
                this.pack.LANG === 'ja' ? lname : fname;
            this.confirmListUserData[1].innerText =
                this.pack.LANG === 'ja' ? fname : lname;
            this.confirmListUserData[2].innerText = company;
            this.confirmListUserData[3].innerText = role;
            this.confirmListUserData[4].innerText = email;
            this.confirmListUserData[5].innerText = requirements;
            this.confirmListUserData[6].innerText = message;
            // this.confirmListUserData[6].innerText =
            //     news === true ? 'Yes' : 'No';
            this.confirmListUserData[7].innerText =
                privacy === true ? 'Yes' : 'No';
            this.section1.classList.add('confirm');
            this.pack.anchorScroll(0);
            this.section2.classList.add('hide');
        }
    }

    clickSubmitHandler(event) {
        const formData = new FormData(document.querySelector('#form1'));
        const data = Object.fromEntries(formData.entries());
        delete data.privacy_policy;

        fetch(this.pack.CONTACT_API_PATH + '/form/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log('Success:', data);
                this.section1.classList.add('thanks');
                this.section2.classList.add('thanks');
                this.pack.anchorScroll(0);
            })
            .catch((error) => {
                console.error('Error:', error);

                //for debug TODO(消す)
                // this.section1.classList.add("thanks");
                // this.section2.classList.add("thanks");
                // this.pack.anchorScroll(0);
                //for debug
            });
    }

    clickBackHandler(event) {
        this.section1.classList.remove('confirm');
        this.section2.classList.remove('confirm');
        this.pack.anchorScroll(0);
    }

    start() {
        this.setValues();
        TweenMax.delayedCall(0.9, () => {
            this.h1.classList.add('show');
        });
    }

    setValues() {
        let items = this.pack.apiJSONList[4].items;
        let html = '';

        //昇順ソート
        items.sort((a, b) => {
            if (a.fields.displayOrder < b.fields.displayOrder) return -1;
            if (a.fields.displayOrder > b.fields.displayOrder) return 1;
            return 0;
        });

        for (let i = 0, len = items.length; i < len; i++) {
            let item = items[i];
            html += this.getHTML(item.fields);
        }

        this.contactsContainer.innerHTML = html;

        this.addresses = document.querySelectorAll(
            'main.contact .section2 .contacts a'
        );
        this.copyToClipboards = document.querySelectorAll(
            'main.contact .section2 .contacts li .copy'
        );
        this.setEvents();
    }

    getHTML(data) {
        let mailAddress = data.mailAddress;
        let title = this.pack.getTitle(data);
        /*
            li.item
                div.tit BUSINESS
                div.address
                    a(href="mailto:xxxx.business@tier4.jp") xxxx.business@tier4.jp
                div.copy Copy to clipboard
         */

        return `
            <li class="item">
              <div class="tit">${title}</div>
              <div class="address">
                <a href="mailto:${mailAddress}">${mailAddress}</a>
              </div>
              <div class="copy">Copy to clipboard</div>
            </li>
        `;
    }

    scrollHandler() {}

    enterframe() {}

    enterframeThinOut() {}

    executeResize() {
        super.executeResize();
    }
}
