import Platform from './Subpage';
// let MarkdownIt = require('markdown-it');

export default class extends Platform {
    constructor(param) {
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset() {
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct() {
        super.destruct();

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars() {
        super.setVars();
        this.protocol = location.protocol;
    }

    setDom() {
        super.setDom();

        this.sections = document.querySelector('main.services .sections');
        this.localNav = document.querySelector(
            'main.services .sections_for_local_nav .local_nav > ul > li > ul'
        );

        this.movie = document.querySelector('main.services .movie');
        this.movieChild = document.querySelector(
            'main.services .movie .movie_child'
        );

        this.pack.setSpansDelay('main.services .hero h1 span.item');
    }

    initEvents() {
        super.initEvents();
    }

    start() {
        super.start();

        this.setValues();
        this.setLocalNav();
        this.setSections();
        this.setMedias();

        this.sectionH3List = document.querySelectorAll(
            'main.subpage .sections .section h3'
        );
    }

    setValues() {
        let items = this.pack.apiJSONList[2].items;
        this.sectionChildLen = items.length;

        for (let i = 0, len = this.sectionChildLen; i < len; i++) {
            let item = items[i].fields;
            let mediaAPIPathList = [];
            let displayOrder = item.displayOrder;
            let subTit = this.pack.getData(item, 'areaTitle');
            let titEn = item['bigHeadlineEn'];
            let titJa = item['bigHeadlineJa'];

            let middleHeadline = this.pack.getData(item, 'middleHeadline');
            let description = this.pack.getData(item, 'description');
            let linkLabel = this.pack.getData(item, 'linkLabel');
            let url = item.url;
            mediaAPIPathList[0] = this.pack.getContentfulAPIImgAccessPath(
                item.image.sys.id
            );
            mediaAPIPathList[1] = this.pack.getContentfulAPIImgAccessPath(
                item.focusImage.sys.id
            );

            let data = {
                displayOrder: displayOrder,
                subTit: subTit,
                titEn: titEn,
                titJa: titJa,
                middleHeadline: middleHeadline,
                description: description,
                linkLabel: linkLabel,
                url: url,
                mediaAPIPathList: mediaAPIPathList,
            };

            this.contentsData.push(data);
        }

        this.contentsData.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    setLocalNav() {
        let src = '';
        for (let i = 0, len = this.contentsData.length; i < len; i++) {
            let item = this.contentsData[i];
            src += this.getLocalNav(item, i);
        }

        this.localNav.innerHTML = src;
        this.localMenuList = document.querySelectorAll(
            'main.services .sections_for_local_nav .local_nav > ul > li > ul > li'
        );
        this.localMenuList[0].classList.add('current');

        for (let i = 0, len = this.localMenuList.length; i < len; i++) {
            let item = this.localMenuList[i];
            item.addEventListener('click', {
                handleEvent: this.clickLocalMenuHandler,
                id: i,
                sc: this,
            });
        }
    }

    getLocalNav(data, id) {
        let subTit = data.subTit.replace(/<br>/g, ''); //全ての<br>を''に置き換える
        let subTitSrc = '';

        for (let i = 0, len = subTit.length; i < len; i++) {
            let value = subTit.substr(i, 1);
            if (value === ' ') subTitSrc += '<span class="item space">';
            else subTitSrc += '<span class="item">';
            subTitSrc += value;
            subTitSrc += '</span>';
        }

        return `
            <li>${subTitSrc}</li>
        `;
    }

    setSections() {
        let src = '';
        for (let i = 0, len = this.contentsData.length; i < len; i++) {
            let item = this.contentsData[i];
            src += this.getSection(item, i);
        }
        this.sections.innerHTML = src + this.sections.innerHTML;
        this.sectionList = document.querySelectorAll(
            'main.services .sections .section'
        );
        this.sectionH2List = document.querySelectorAll(
            'main.services .sections .section h2'
        );

        this.pack.setSpansDelay(
            'main.services .sections .section h2 .tit span'
        );
    }

    getSection(data, id) {
        let section1ClassName = 'section' + (id + 1);
        let patern = (id % 2) + 1;
        let subTit = data.subTit;
        let subTitArray = subTit.split('<br>');
        let subTitSrc = '';

        let titJa = data.titJa;
        let titEn = data.titEn;
        let titArray = titEn.split('<br>');
        let titSrc = '';

        let middleHeadline = data.middleHeadline;
        let middleHeadlineSrc = '';
        for (let i = 0, len = middleHeadline.length; i < len; i++) {
            middleHeadlineSrc += '<span>' + middleHeadline[i] + '</span>';
        }

        let description = data.description;
        let linkLabel = data.linkLabel;
        let url = data.url;

        //subTit
        for (let h = 0; h < subTitArray.length; h++) {
            let subTit = subTitArray[h];
            subTitSrc += '<span class="span_chunk">';
            for (let i = 0, len = subTit.length; i < len; i++) {
                let value = subTit.substr(i, 1);
                if (value === ' ') subTitSrc += '<span class="item space">';
                else subTitSrc += '<span class="item">';
                subTitSrc += value;
                subTitSrc += '</span>';
            }
            subTitSrc += '</span>';
        }

        //tit
        for (let h = 0; h < titArray.length; h++) {
            let tit = titArray[h];
            if (h === 0) titSrc += '<span class="span_chunk chunk1">';
            else titSrc += '<span class="span_chunk">';
            for (let i = 0, len = tit.length; i < len; i++) {
                let value = tit.substr(i, 1);
                if (value === ' ') titSrc += '<span class="item space">';
                else titSrc += '<span class="item">';
                titSrc += value;
                titSrc += '</span>';
            }
            titSrc += '</span>';
        }

        let btn = '';
        if (url) {
            let linkLabelSrc = '';
            for (let i = 0, len = linkLabel.length; i < len; i++) {
                let value = linkLabel.substr(i, 1);
                if (value === ' ') linkLabelSrc += '<span class="item space">';
                else linkLabelSrc += '<span class="item">';
                linkLabelSrc += value;
                linkLabelSrc += '</span>';
            }

            btn = `
                <div class="btn">
                  <a href="${url}" target="_blank">
                    ${linkLabelSrc}
                  </a>
                </div>
            `;
        }

        let japaneseTit = '';

        if (this.pack.LANG === 'ja') {
            japaneseTit = `
                <span class="font2 tit_ja">${titJa}</span>
            `;
        }

        let addTxtClass = '';
        if (this.pack.LANG === 'ja') addTxtClass = ' font2';

        return `
            <section class="section ${section1ClassName} patern${patern}" id="${section1ClassName}">
                <div class="section_child">
                    <h2>
                      <span class="sub_tit">
                        ${subTitSrc}
                      </span>
                      <span class="tit">
                        ${titSrc}
                      </span>
                      ${japaneseTit}
                    </h2>
                    <section class="child_section">
                        <div class="chunk">
                            <h3 class="${addTxtClass}">${middleHeadlineSrc}</h3>
                            <p class="txt${addTxtClass}">${description}</p>
                            ${btn}
                        </div>
                        <div class="imgs">
                            <div class="img img1">
                              <img src="">
                            </div>
                            <div class="img img2">
                              <img src="">
                            </div>
                        </div>
                    </section>
                </div>
            </section>        
        `;
    }

    setMedias() {
        for (let i = 0, len = this.contentsData.length; i < len; i++) {
            let item = this.contentsData[i];
            let mediaAPIPathList = item.mediaAPIPathList;
            this.loadMediaPathes(mediaAPIPathList, i);
        }
    }

    loadMediaPathes(list, id) {
        let urlList = list;
        let promiseList = [];
        let mediaPathList = [];
        urlList.forEach((url, i) => {
            promiseList.push(
                fetch(url)
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        // trace(id, res);
                        mediaPathList[i] = this.protocol + res.fields.file.url;
                    })
            );
        });

        Promise.all(promiseList).then(() => {
            this.setSectionMedias(id, mediaPathList);
        });
    }

    setSectionMedias(id, mediaPathList) {
        let section = this.sectionList[id];
        let img1 = section.querySelector('.img1 img');
        let img2 = section.querySelector('.img2 img');

        img1.src = mediaPathList[0];
        img2.src = mediaPathList[1];
    }

    scrollHandler() {
        super.scrollHandler();
        this.checkMovie();
    }

    checkMovie() {
        let height = 500;

        let ratio = Math.min(this.pack.pageYOffset / height, 1);
        let invRatio = 1 - Math.min(this.pack.pageYOffset / height, 1);
        let borderRadius, width;
        if (this.sw >= this.pack.BP) {
            borderRadius = 2.2222222222 * invRatio;
            width = 89 + 11 * ratio;
        } else {
            borderRadius = 4.2666666667 * invRatio;
            width = 89 + 11 * ratio;
        }

        this.movieChild.style.borderRadius = borderRadius + 'rem';
        this.movieChild.style.width = width + 'vw';
    }

    enterframe() {}

    enterframeThinOut() {}

    executeResize() {
        super.executeResize();
    }
}
