import TemplateContents from './TemplateContents';
import JsFileDownloader from 'js-file-downloader';

export default class extends TemplateContents {
    constructor(param) {
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset() {
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct() {
        super.destruct();

        this.confirmBtn.removeEventListener(
            'click',
            this.bindClickConfirmHandler
        );
        // this.submitBtn.removeEventListener(
        //     'click',
        //     this.bindClickSubmitHandler
        // );
        // this.backBtn.removeEventListener('click', this.bindClickBackHandler);

        for (let i = 0, len = this.inputRowfields.length; i < len; i++) {
            let item = this.inputRowfields[i];
            item.removeEventListener('focus', this.bindFocusHandler[i]);
            item.removeEventListener('blur', this.bindBlurHandler[i]);
        }

        // for (let i = 0, len = this.copyToClipboards.length; i < len; i++) {
        //     let item = this.copyToClipboards[i];
        //     item.removeEventListener(
        //         'click',
        //         this.bincClickCopyToClipboardHandlerList[i]
        //     );
        // }

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars() {
        super.setVars();
    }

    setDom() {
        super.setDom();

        // this.contactsContainer = document.querySelector(
        //     'main.product_brochure .section2 .contacts'
        // );

        this.h1 = document.querySelector('main.product_brochure h1');
        this.section1 = document.querySelector(
            'main.product_brochure .section1'
        );
        // this.section2 = document.querySelector(
        //     'main.product_brochure .section2'
        // );
        this.inputList = document.querySelectorAll(
            'main.product_brochure .section1 .contents_input ul li.item'
        );
        this.inputRowTits = document.querySelectorAll(
            'main.product_brochure .section1 .contents_input .input_item .tit'
        );
        this.inputRowfields = document.querySelectorAll(
            'main.product_brochure .section1 .contents_input .input_item input, main.product_brochure .section1 .contents_input .input_item textarea'
        );
        // this.confirmList = document.querySelectorAll(
        //     'main.product_brochure .section1 .contents_confirm ul li.item'
        // );
        // this.confirmListUserData = document.querySelectorAll(
        //     'main.product_brochure .section1 .contents_confirm ul li.item .user_data'
        // );
        this.confirmBtn = document.querySelector(
            'main.product_brochure .section1 .contents_input .bt'
        );
        // this.submitBtn = document.querySelector(
        //     'main.product_brochure .section1 .contents_confirm .submit_bt'
        // );
        // this.backBtn = document.querySelector(
        //     'main.product_brochure .section1 .contents_confirm .back_bt'
        // );
    }

    initEvents() {
        super.initEvents();

        this.bindClickConfirmHandler = this.clickConfirmHandler.bind(this);
        this.bindClickSubmitHandler = this.clickSubmitHandler.bind(this);
        // this.bindClickBackHandler = this.clickBackHandler.bind(this);
        this.bindFocusHandler = [];
        this.bindBlurHandler = [];

        this.confirmBtn.addEventListener('click', this.bindClickConfirmHandler);
        // this.submitBtn.addEventListener('click', this.bindClickSubmitHandler);
        // this.backBtn.addEventListener('click', this.bindClickBackHandler);

        for (let i = 0, len = this.inputRowfields.length; i < len; i++) {
            let item = this.inputRowfields[i];
            this.bindFocusHandler[i] = this.focusHandler;
            this.bindBlurHandler[i] = this.blurHandler;
            item.addEventListener('focus', {
                handleEvent: this.bindFocusHandler[i],
                id: i,
                sc: this,
            });
            item.addEventListener('blur', {
                handleEvent: this.bindBlurHandler[i],
                id: i,
                sc: this,
            });
        }
    }

    setEvents() {
        // this.bincClickCopyToClipboardHandlerList = [];
        // for (let i = 0, len = this.copyToClipboards.length; i < len; i++) {
        //     let item = this.copyToClipboards[i];
        //     this.bincClickCopyToClipboardHandlerList[i] =
        //         this.clickCopyToClipboardHandlerList;
        //     item.addEventListener('click', {
        //         handleEvent: this.bincClickCopyToClipboardHandlerList[i],
        //         sc: this,
        //         id: i,
        //     });
        // }
    }

    clickCopyToClipboardHandlerList(event) {
        let sc = this.sc;
        let id = this.id;
        navigator.clipboard.writeText(sc.addresses[id].innerText);
    }

    focusHandler(event) {
        let sc = this.sc;
        let id = this.id;

        sc.inputRowTits[id].classList.add('small');
    }

    blurHandler(event) {
        let sc = this.sc;
        let id = this.id;
        // trace(sc.inputRowfields[id].value);
        if (sc.inputRowfields[id].value === '')
            sc.inputRowTits[id].classList.remove('small');
    }

    checkName(v) {
        if (v == '') return false;
        else return true;
    }

    checkEmail(v) {
        if (v == '') return false;
        else if (
            !v.match(
                /^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/
            )
        )
            return false;
        else return true;
    }

    checkMessage(v) {
        if (v == '') return false;
        else return true;
    }

    clickConfirmHandler(event) {
        let fname = document.querySelector('input[name="fname"]');
        let lname = document.querySelector('input[name="lname"]');
        let email = document.querySelector('input[name="email"]');
        let company = document.querySelector('input[name="company"]');
        let privacy = document.querySelector('input[name="privacy_policy"]');

        let fnameResult = this.checkName(fname.value);
        let lnameResult = this.checkName(lname.value);
        let companyResult = this.checkName(company.value);
        let emailResult = this.checkEmail(email.value);
        let privacyResult = privacy.checked;

        let liFName = fname.parentElement.parentElement;
        let liLName = lname.parentElement.parentElement;
        let liEmail = email.parentElement.parentElement;
        let liCompany = company.parentElement.parentElement;
        let liPrivacy = privacy.parentElement.parentElement;

        if (fnameResult) liFName.classList.remove('error');
        else liFName.classList.add('error');

        if (fnameResult) liLName.classList.remove('error');
        else liLName.classList.add('error');

        if (emailResult) liEmail.classList.remove('error');
        else liEmail.classList.add('error');

        if (companyResult) liCompany.classList.remove('error');
        else liCompany.classList.add('error');

        if (privacyResult) liPrivacy.classList.remove('error');
        else liPrivacy.classList.add('error');

        if (
            fnameResult &&
            lnameResult &&
            emailResult &&
            companyResult &&
            privacyResult
        ) {
            this.bindClickSubmitHandler(event);
        }
    }

    clickSubmitHandler(event) {
        const formData = new FormData(document.querySelector('#form1'));
        const data = Object.fromEntries(formData.entries());
        delete data.privacy_policy;

        fetch(this.pack.CONTACT_API_PATH + '/form/whitepaper', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log('Success:', data);
                this.section1.classList.add('thanks');
                // this.section2.classList.add('thanks');
                this.pack.anchorScroll(0);

                new JsFileDownloader({
                    url: `/assets/img/product-brochure/download_${this.pack.LANG}.pdf`,
                    filename: 'product-brochure.pdf',
                });
            })
            .catch((error) => {
                console.error('Error:', error);

                //for debug TODO(消す)
                // this.section1.classList.add("thanks");
                // this.section2.classList.add("thanks");
                // this.pack.anchorScroll(0);
                //for debug
            });
    }

    clickBackHandler(event) {
        this.section1.classList.remove('confirm');
        // this.section2.classList.remove('confirm');
        this.pack.anchorScroll(0);
    }

    start() {
        this.setValues();
        TweenMax.delayedCall(0.9, () => {
            this.h1.classList.add('show');
        });
    }

    setValues() {
        // let items = this.pack.apiJSONList[4].items;
        // let html = '';

        // //昇順ソート
        // items.sort((a, b) => {
        //     if (a.fields.displayOrder < b.fields.displayOrder) return -1;
        //     if (a.fields.displayOrder > b.fields.displayOrder) return 1;
        //     return 0;
        // });

        // for (let i = 0, len = items.length; i < len; i++) {
        //     let item = items[i];
        //     html += this.getHTML(item.fields);
        // }

        // this.contactsContainer.innerHTML = html;

        // this.addresses = document.querySelectorAll(
        //     'main.product_brochure .section2 .contacts a'
        // );
        // this.copyToClipboards = document.querySelectorAll(
        //     'main.product_brochure .section2 .contacts li .copy'
        // );
        this.setEvents();
    }

    getHTML(data) {
        let mailAddress = data.mailAddress;
        let title = this.pack.getTitle(data);
        /*
            li.item
                div.tit BUSINESS
                div.address
                    a(href="mailto:xxxx.business@tier4.jp") xxxx.business@tier4.jp
                div.copy Copy to clipboard
         */

        return `
            <li class="item">
              <div class="tit">${title}</div>
              <div class="address">
                <a href="mailto:${mailAddress}">${mailAddress}</a>
              </div>
              <div class="copy">Copy to clipboard</div>
            </li>
        `;
    }

    scrollHandler() {}

    enterframe() {}

    enterframeThinOut() {}

    executeResize() {
        super.executeResize();
    }
}
